import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";
import SEO from "../../seo";

const details = {
  title: "Vikhe Patil Senior Secondary School, Pune",
  address: "Pune, Maharashtra",
  client: "Padamshri Dr. Vithal Rao Vikhe Patil Foundation",
  area: "1,50,000 Sq. Ft.",
  project: "Vikhe Patil Senior Secondary School, Pune",
  category: "Institutions | Architecture | School",
  status: "Under Design Approval",
  backlink: "/projects/institutions/",
};

export default class ProjectvpsssPune extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="A&D Studio | Institutional Projects | Vikhe Patil Senior Secondary School, Pune"
          img="https://archdesignsstudio.com/assets/linkpreview.png"
          description="View the site plans and designed layout that combines modern and traditional elements for directed learning at a new senior secondary school in Maharashtra."
        />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
